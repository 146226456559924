import React, { useState, useEffect } from "react";

export default function ServiceGuideDescription({
  type,
  title,
  content,
  follow,
  img,
}) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  if (width >= 640) {
    return (
      <div className="flex items-center my-20 justify-between">
        {type == "left" && (
          <img
            className="w-1/3 xl:w-1/2 2xl:w-3/5 xl:px-10"
            src={process.env.PUBLIC_URL + img}
          ></img>
        )}
        <div className="xl:px-10">
          <p className="text-xs text-gray-500 font-bold">{title}</p>
          <h1 className="font-extrabold whitespace-pre text-2xl">{content}</h1>
          <p className="whitespace-pre">{follow}</p>
        </div>
        {type == "right" && (
          <img
            className="w-1/3 xl:w-1/2 2xl:w-3/5 xl:px-10"
            src={process.env.PUBLIC_URL + img}
          ></img>
        )}
      </div>
    );
  } else {
    return (
      <div className="flex-col items-center my-20 justify-center text-center space-y-3">
        <div className="space-y-3">
          <p className="text-xs text-gray-500 font-bold">{title}</p>
          <h1 className="font-extrabold whitespace-pre-line">{content}</h1>
          <p className="whitespace-pre-line">{follow}</p>
        </div>
        <img
          className="w-8/12 self-center m-auto"
          src={process.env.PUBLIC_URL + img}
        ></img>
      </div>
    );
  }
}
