import React, { useRef, useEffect } from "react";
import { createPortal } from "react-dom";

export default function DialogExpertOnly({ isOpen, onClose }) {
  const dialogRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return createPortal(
    <>
      <div className="fixed inset-0 bg-black/60 z-40"></div>
      <div
        ref={dialogRef}
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg z-50 w-1/3 py-5 shadow-lg"
      >
        <div className="flex flex-col py-3 px-5 place-items-center space-y-5">
          <h4 className="font-extrabold py-3 text-xl">전문가 전용 서비스</h4>
          <p className="font-semibold">
            전문가 회원만 확인할 수 있는 메뉴입니다.
          </p>
          <button
            className="bg-black text-white rounded-full w-1/3 py-1"
            onClick={onClose}
          >
            확인
          </button>
        </div>
      </div>
    </>,
    document.body
  );
}
