import React, { useEffect, useState } from "react";
import { BsFilePersonFill } from "react-icons/bs";
import { FaBell } from "react-icons/fa";
import { MdArrowDropDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/lawfin-logo.png";
import text_logo from "../assets/images/text-logo.png";
import { logout } from "../hook/authReducer";
import apiClient from "../services/apiClitent";
import DialogExpertOnly from "./DialogExpertOnly";
import DialogMemberOnly from "./DialogMemberOnly";

const Header = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [isExpertDialogOpen, setIsExpertDialogOpen] = useState(false);

  const handleOpenExpertModal = () => {
    setIsExpertDialogOpen(true);
  };

  const handleCloseExpertModal = () => {
    setIsExpertDialogOpen(false);
  };

  const [isMemberDialogOpen, setIsMemberDialogOpen] = useState(false);

  const handleCloseMemberModal = () => {
    setIsMemberDialogOpen(false);
  };

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    apiClient.defaults.headers.common["Authorization"] = null;
    dispatch(logout());
  };

  return (
    <div className="tabletS:container tabletS:mx-auto font-bold text-xs md:text-sm">
      <header className="bg-white tabletS:box-shadow">
        <div className="flex justify-between items-center py-3 px-6">
          <div
            className="flex items-center space-x-2"
            onClick={() => navigate("/")}
          >
            <img src={logo} alt="Logo" className="h-8" />
            {width >= 640 && <img src={text_logo} alt="Logo" className="h-4" />}
          </div>
          <nav className="flex space-x-4 items-center">
            {width >= 640 && (
              <>
                <a
                  href="#!"
                  className="hover:bg-gray-100 text-black rounded-full py-1.5  px-2"
                >
                  이용방법
                </a>
                <div className="relative group">
                  <button className="flex items-center space-x-1 p-1.5">
                    <span>서비스 안내</span>
                    <MdArrowDropDown color="grey" />
                  </button>
                  <div className="group-hover:block absolute hidden m-0 p-0">
                    <ul
                      role="menu"
                      data-popover="menu"
                      data-popover-placement="bottom"
                      className="absolute min-w-[120px] overflow-auto rounded-lg bg-white p-1.5 shadow-lg focus:outline-none z-50"
                    >
                      <li
                        role="menuitem"
                        className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1.5 transition-all hover:bg-slate-100"
                        onClick={() => navigate("/normal_guide")}
                      >
                        일반인 서비스
                      </li>
                      <li
                        role="menuitem"
                        className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1.5 transition-all hover:bg-slate-100"
                        onClick={() => navigate("/prof_guide")}
                      >
                        전문가 서비스
                      </li>
                      <li className="border" />
                      <li
                        role="menuitem"
                        className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1.5 transition-all hover:bg-slate-100"
                        onClick={handleOpenExpertModal}
                      >
                        전문가 메뉴얼
                      </li>
                      <li className="border" />
                      <li
                        role="menuitem"
                        className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1.5 transition-all hover:bg-slate-100"
                      >
                        공지사항
                      </li>
                      <li
                        role="menuitem"
                        className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1.5 transition-all hover:bg-slate-100"
                      >
                        업데이트 안내
                      </li>
                      <li className="border" />
                      <li
                        role="menuitem"
                        className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1.5 transition-all hover:bg-slate-100"
                      >
                        1:1 고객센터
                      </li>
                      <li className="border" />
                      <li
                        role="menuitem"
                        className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1.5 transition-all hover:bg-slate-100"
                      >
                        회사 소개
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="relative group">
                  <button className="flex items-center space-x-1 p-1.5">
                    <span>전문가 등록</span>
                    <MdArrowDropDown color="grey" />
                  </button>
                  <div className="group-hover:block absolute hidden m-0 p-0">
                    <ul
                      role="menu"
                      data-popover="menu"
                      data-popover-placement="bottom"
                      className="absolute min-w-[120px] overflow-auto rounded-lg bg-white p-1.5 shadow-lg focus:outline-none z-50"
                    >
                      <li
                        role="menuitem"
                        className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1 transition-all hover:bg-slate-100"
                        onClick={() => {
                          navigate("/contract");
                        }}
                      >
                        전문가 신규 등록
                      </li>
                      <li
                        role="menuitem"
                        className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1 transition-all hover:bg-slate-100"
                        onClick={() => {
                          if (auth.isAuthenticated) {
                            navigate("/contract");
                          } else {
                            setIsMemberDialogOpen(true);
                          }
                        }}
                      >
                        소속원 신청
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            )}
            {auth.isAuthenticated && (
              <>
                <FaBell color="#999999" size={20} />
                <BsFilePersonFill color="#999999" size={20} />
                <div className="relative group">
                  <button className="flex items-center space-x-1 p-1.5 hover:bg-bgColor rounded-full">
                    {auth.user?.auth === "ROLE_MEMBER" ? (
                      <div className="rounded-full bg-gray3 py-1 px-2 text-white text-xs">
                        일반
                      </div>
                    ) : (
                      <div className="rounded-full bg-pointDark py-1 px-2 text-white text-xs">
                        전문가
                      </div>
                    )}
                    {width >= 768 && <span>{auth.user?.name}</span>}
                    <MdArrowDropDown color="grey" />
                  </button>
                  <div className="group-hover:block absolute hidden m-0 p-0">
                    <ul
                      role="menu"
                      data-popover="menu"
                      data-popover-placement="bottom"
                      className="absolute min-w-[120px] overflow-auto rounded-lg bg-white p-1.5 shadow-lg focus:outline-none z-50"
                    >
                      <li
                        role="menuitem"
                        className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1 transition-all hover:bg-slate-100"
                        onClick={() => navigate("/my_home/switch")}
                      >
                        계정관리
                      </li>
                      <li
                        role="menuitem"
                        className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1 transition-all hover:bg-slate-100"
                        onClick={handleLogout}
                      >
                        로그아웃
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            )}
            {!auth.isAuthenticated && (
              <div>
                <button
                  className="bg-gray-100 rounded-full px-3 py-1.5 mr-3"
                  onClick={() => navigate("/join")}
                >
                  회원 가입
                </button>
                <button
                  className="bg-gray-100 rounded-full px-3 py-1.5"
                  onClick={() => navigate("/login")}
                >
                  로그인
                </button>
              </div>
            )}
          </nav>
        </div>
        {width < 640 && (
          <div className="bg-white py-1.5 px-3">
            <button className="bg-pointDark text-white py-1 px-3 rounded-full shadow-xl">
              일반인
            </button>
            <button className="bg-white text-black py-1 px-3 rounded-full shadow-xl">
              전문가
            </button>
          </div>
        )}
      </header>
      <DialogExpertOnly
        isOpen={isExpertDialogOpen}
        onClose={handleCloseExpertModal}
      />
      <DialogMemberOnly
        isOpen={isMemberDialogOpen}
        onClose={handleCloseMemberModal}
      />
    </div>
  );
};

export default Header;
