import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl } from "@mui/material";

export default function InputDropDown({
  label,
  value,
  setValue,
  field,
  menuItems,
  setError,
  required = true,
}) {
  const handleChange = (e) => {
    setValue((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        id="demo-simple-select"
        value={value}
        label={label}
        onChange={handleChange}
        required={required}
        error={setError}
      >
        {menuItems.map((e, idx) => {
          return (
            <MenuItem key={idx} value={e}>
              {e}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
