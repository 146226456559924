import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function CustomCarousel() {
  var items = [
    {
      img: "/carousel/client-info-main-01.png",
      description: "사건의 예상견적을 확인해보세요!",
      follow: "소송 및 등기 사건의 예상견적을 확인 할 수 있습니다.",
    },
    {
      img: "/carousel/client-info-main-02.png",
      description: "내용증명을 무료로 작성하세요!",
      follow: "대여금에서 임대차보증금까지 자동작성 할 수 있습니다.",
    },
    {
      img: "/carousel/client-info-main-03.png",
      description: "사건진행현황을 확인하세요!",
      follow: "위임 전문가로부터 사건현황 및 진행률을 확인 할 수 있습니다",
    },
  ];

  const settings = {
    className: "",
    dots: true,
    adaptiveHeight: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <div className="px-6">
      <Slider {...settings}>
        {items.map((item, i) => (
          <Item
            key={i}
            img={item.img}
            description={item.description}
            follow={item.follow}
          />
        ))}
      </Slider>
    </div>
  );
}

function Item({ img, description, follow }) {
  return (
    <div className="flex flex-col pt-7 h-full">
      <img
        src={process.env.PUBLIC_URL + img}
        className="w-60 md:w-1/3 py-3 h-auto"
        key={img}
      ></img>
      <h2 className="font-bold text-2xl py-2">{description}</h2>
      <p className="py-2 text-xs tabletS:text-sm font-semibold ">{follow}</p>

      <a
        href="#"
        className="underline underline-offset-4 text-black font-bold py-2"
      >
        자세히 보기
      </a>
    </div>
  );
}
