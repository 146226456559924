import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignUpPage from "./pages/SignUpPage";
import SignInPage from "./pages/SignInPage";
import Guide from "./pages/ServiceGuidePage";
import HomePage from "./pages/HomePage";
import { Provider } from "react-redux";
import store from "./hook/store";
import MyHomePage from "./pages/MyHomePage";
import MyHomeSwitchPage from "./pages/MyHomeSwitch";
import ChangePersonalInfoPage from "./pages/ChangePersonalInfoPage";
import ExpertContractPage from "./pages/ExpertContractPage";
import ProtectedRoute from "./component/ProtectedRoute";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/join" element={<SignUpPage />} />
        <Route path="/login" element={<SignInPage />} />
        <Route path="/normal_guide" element={<Guide type="normal" />} />
        <Route path="/prof_guide" element={<Guide type="prof" />} />
        <Route path="/my_home" element={<MyHomePage />} />
        <Route path="/my_home/switch" element={<MyHomeSwitchPage />} />
        <Route path="/user_modify/info" element={<ChangePersonalInfoPage />} />
        <Route
          path="/contract"
          element={<ProtectedRoute component={ExpertContractPage} />}
        />
      </Routes>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
