import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { IoPersonCircle } from "react-icons/io5";
import { ImDrawer } from "react-icons/im";
import DrawerDropDown from "./DrawerDropDown";

function Drawer({ onClose }) {
  const [isCertificateOpen, setIsCertificateOpen] = useState(false);
  const [isConsultManagement, setIsConsultManagement] = useState(false);
  const [isExperManagement, setIsExperManagement] = useState(false);
  const [isServiceManagement, setIsServiceManagement] = useState(false);
  const [isAddExpert, setIsAddExpert] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <div
        className="fixed inset-0 bg-black opacity-50 z-40"
        onClick={onClose}
      ></div>

      <div className="fixed left-0 top-0 w-9/12 h-full bg-white z-50 overflow-y-auto">
        <div className="border-b-2 border-gray-500 bg-bgColor pt-4">
          <div className="flex flex-col justify-center items-center space-y-3">
            <img
              src={process.env.PUBLIC_URL + "/user/expert.png"}
              alt="Logo"
              className="h-14"
            />
            <h3 className="font-semibold text-lg">이은서</h3>
            <span className="font-semibold text-sm">test@test.com</span>
          </div>
          <div className="flex mt-5 mx-5 font-semibold">
            <button className="w-1/2 bg-gray-200 rounded-full py-2 mx-2 mb-4">
              마이 로팀
            </button>
            <button className="w-1/2 border rounded-full py-2 mx-2 mb-4">
              정보 변경
            </button>
          </div>
        </div>

        <div className="font-semibold">
          <div className="grid grid-cols-4 gap-3 border-b-2 border-gray-200 text-sm px-2 py-5">
            <button className="rounded-full w-16 h-16 bg-[#f8f8f8]">
              상담요청
            </button>
            <button className="rounded-full w-16 h-16 bg-[#f8f8f8]">
              견적서작성
            </button>
            <button className="rounded-full w-16 h-16 bg-[#f8f8f8]">
              사건관리
            </button>
            <button className="rounded-full w-16 h-16 bg-[#f8f8f8]">
              상담내역
            </button>
            <button className="rounded-full w-16 h-16 bg-[#f8f8f8]">
              상담관리
            </button>
            <button className="rounded-full w-16 h-16 bg-[#f8f8f8]">
              내용증명
            </button>
            <button className="rounded-full w-16 h-16 bg-[#f8f8f8]">
              소속원설정
            </button>
            <button className="rounded-full w-16 h-16 bg-[#f8f8f8]">
              전문가정보
            </button>
          </div>

          <DrawerDropDown
            title="상담관리"
            isOpen={isConsultManagement}
            setIsOpen={setIsConsultManagement}
            menus={[
              "상담 요청",
              "견적서 신규 작성",
              "상담 내역",
              "보수표 설정",
            ]}
          />
          <div className="border-b-2 border-gray-200  py-3 px-3">
            <span>사건 관리</span>
          </div>
          <DrawerDropDown
            title="내용증명"
            isOpen={isCertificateOpen}
            setIsOpen={setIsCertificateOpen}
            menus={["자동작성", "작성 리스트", "서비스 안내"]}
          />
          <div className="border-b-2 border-gray-200  py-3 px-3">
            <span>사건 사례</span>
          </div>
          <DrawerDropDown
            title="전문가 운영 관리"
            isOpen={isExperManagement}
            setIsOpen={setIsExperManagement}
            menus={[
              "나의 수임사건",
              "전문가 통계",
              "파트너 수익배분설정",
              "소속원 설정",
              "전문가 정보 관리",
              "주소록",
            ]}
          />
          <DrawerDropDown
            title="서비스 이용 관리"
            isOpen={isServiceManagement}
            setIsOpen={setIsServiceManagement}
            menus={[
              "서비스 계정 전환",
              "전문가사무소 추가 등록",
              "소속원 신청",
            ]}
          />
          <DrawerDropDown
            title="전문가 추가"
            isOpen={isAddExpert}
            setIsOpen={setIsAddExpert}
            menus={["전문가사무소 추가 등록", "소속원 신청"]}
          />
          <div className="flex flex-col space-y-4 px-3 py-3 cursor-pointer border-b-2 border-gray-200">
            <span onClick={() => navigate("/normal_guide")}>
              일반인 서비스 안내
            </span>
            <span onClick={() => navigate("/prof_guide")}>
              전문가 서비스 안내
            </span>
            <span>이용방법</span>
            <span>공지사항</span>
            <span>업데이트 안내</span>
            <span>1:1 고객센터</span>
            <span>회사 소개</span>
          </div>
          <div className="px-3 py-3 cursor-pointer">
            <span>전문가 서비스 메뉴얼</span>
          </div>
        </div>
      </div>
    </>
  );
}

function ExpertBottomNav() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <div className="flex flex-col justify-between z-30">
      {isDrawerOpen && <Drawer onClose={handleDrawerClose} />}

      <div className="fixed bottom-0 w-full bg-white">
        <div className="flex justify-around py-2">
          <button
            className="flex flex-col items-center text-gray-600"
            onClick={() => setIsDrawerOpen(true)}
          >
            <FaBars size={20} />
            <span className="text-sm">메뉴</span>
          </button>
          <button className="flex flex-col items-center text-gray-600">
            <IoPersonCircle size={20} />
            <span className="text-sm">마이 로팀</span>
          </button>
          <button className="flex flex-col items-center text-gray-600">
            <ImDrawer size={20} />
            <span className="text-sm">사건관리</span>
          </button>
          <button className="flex flex-col items-center text-gray-600">
            <BsFillQuestionCircleFill size={20} />
            <span className="text-sm">상담관리</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ExpertBottomNav;
