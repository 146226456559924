import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Layout from "../component/Layout";

export default function MyHomePage() {
  const [width, setWidth] = useState(window.innerWidth);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Layout>
      <div className="flex flex-col items-center tabletS:container mx-auto space-y-10 p-10">
        <div className="sm:flex w-full justify-center">
          <div className="flex flex-col items-center space-y-1.5">
            <img
              src={process.env.PUBLIC_URL + "/user/user.png"}
              alt="Logo"
              className="w-16 h-16"
            />
            <h2 className="text-lg font-semibold">{auth.user?.name}</h2>
            <p className="text-gray-500 text-sm">{auth.user?.sub}</p>
            <button className="bg-pointLight text-white font-bold rounded-full p-1 px-2 w-fit text-sm hover:bg-pointDark">
              정보변경
            </button>
          </div>

          <div className="flex grow sm:justify-end sm:place-items-end space-x-2 justify-center mt-10">
            <div className="text-center px-3 border-r-2 text-sm">
              <p className="font-semibold">0</p>
              <h3 className="">진행사건</h3>
            </div>
            <div className="text-center px-3 border-r-2 text-sm">
              <p className="font-semibold">0</p>
              <h3 className="">상담신청</h3>
            </div>
            <div className="text-center px-3 text-sm">
              <p className=" font-semibold">0</p>
              <h3 className="">내용증명 의뢰</h3>
            </div>
          </div>
        </div>

        <div className="flex w-full space-x-40 items-stretch font-semibold">
          {width >= 640 && (
            <ul className="flex flex-col text-left space-y-7">
              <li className="text-gray-700 cursor-pointer">전문가 등록</li>
              <li className="text-gray-700 cursor-pointer">전문가 소송신청</li>
              <li className="text-gray-700 cursor-pointer">SNS 계정관리</li>
              <li className="text-gray-700 cursor-pointer">서비스 계정전환</li>
            </ul>
          )}

          <div className="flex flex-col grow bg-white px-6 shadow-md h-96 text-xs sm:text-base">
            <div className="flex justify-between border-b-2 py-7">
              <h4 className="font-medium">사건 진행정보</h4>
              <button className="text-black">전체보기</button>
            </div>

            <div className="flex justify-between border-b-2 py-7">
              <h4 className="font-medium">상담 신청</h4>
              <button className="text-black">전체보기</button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
