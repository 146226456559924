import { MdArrowDropDown } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Header2() {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  return (
    <div className="tabletS:container mx-auto font-bold text-sm">
      <header className="bg-white shadow-sm">
        <div className="mx-auto flex justify-between items-center pb-2 px-6">
          {!isAuthenticated ? (
            <div className=" flex space-x-4 items-center">
              <button className="bg-pointDark text-white py-1 px-4 rounded-full shadow-xl">
                일반인
              </button>
              <button className="bg-white text-black py-1 px-4 rounded-full shadow-xl">
                전문가
              </button>
            </div>
          ) : (
            <div></div>
          )}
          <div className="flex space-x-4">
            <a
              href="#!"
              className="hover:bg-gray-100 no-underline text-black rounded-full py-1.5  px-2"
            >
              사건 진행 안내
            </a>
            <div className="relative group">
              <button className="flex items-center space-x-1 p-1.5">
                <span>무료 견적상담</span>
                <MdArrowDropDown color="grey" />
              </button>
              <div className="group-hover:block absolute hidden m-0 p-0">
                <ul
                  role="menu"
                  data-popover="menu"
                  data-popover-placement="bottom"
                  className="absolute min-w-[120px] overflow-auto rounded-lg bg-white p-1.5 shadow-lg focus:outline-none z-50"
                >
                  <li
                    role="menuitem"
                    className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1 transition-all hover:bg-slate-100"
                  >
                    상담 신청
                  </li>
                  <li
                    role="menuitem"
                    className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1 transition-all hover:bg-slate-100"
                  >
                    상담 내역
                  </li>
                </ul>
              </div>
            </div>
            <div className="relative group">
              <button className="flex items-center space-x-1 p-1.5">
                <span>무료 내용증명</span>
                <MdArrowDropDown color="grey" />
              </button>
              <div className="group-hover:block absolute hidden m-0 p-0">
                <ul
                  role="menu"
                  data-popover="menu"
                  data-popover-placement="bottom"
                  className="absolute min-w-[120px] overflow-auto rounded-lg bg-white p-1.5 shadow-lg focus:outline-none  z-50"
                >
                  <li
                    role="menuitem"
                    className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1 transition-all hover:bg-slate-100"
                  >
                    신규작성
                  </li>
                  <li
                    role="menuitem"
                    className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1 transition-all hover:bg-slate-100 focus:bg-slate-100 active:bg-slate-100"
                  >
                    작성내역
                  </li>
                  <li
                    role="menuitem"
                    className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1 transition-all hover:bg-slate-100 focus:bg-slate-100 active:bg-slate-100"
                  >
                    이용 방법
                  </li>
                </ul>
              </div>
            </div>
            {isAuthenticated && (
              <div className="relative group">
                <button className="flex items-center space-x-1 p-1.5">
                  <span>마이홈</span>
                  <MdArrowDropDown color="grey" />
                </button>
                <div className="group-hover:block absolute hidden m-0 p-0">
                  <ul className="absolute min-w-[120px] overflow-auto rounded-lg bg-white p-1.5 shadow-lg space-y-2 z-50">
                    <li
                      role="menuitem"
                      className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1 transition-all hover:bg-slate-100"
                      onClick={() => navigate("/my_home")}
                    >
                      마이홈
                    </li>
                    <li
                      role="menuitem"
                      className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1 transition-all hover:bg-slate-100 focus:bg-slate-100 active:bg-slate-100"
                    >
                      결제내역
                    </li>
                    <li
                      role="menuitem"
                      className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1 transition-all hover:bg-slate-100 focus:bg-slate-100 active:bg-slate-100"
                    >
                      전문가 등록
                    </li>
                    <li
                      role="menuitem"
                      className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1 transition-all hover:bg-slate-100 focus:bg-slate-100 active:bg-slate-100"
                    >
                      전문가 소속신청
                    </li>
                    <li className="border-b" />
                    <li
                      role="menuitem"
                      className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1 transition-all hover:bg-slate-100 focus:bg-slate-100 active:bg-slate-100"
                    >
                      SNS 계정 관리
                    </li>
                    <li
                      role="menuitem"
                      className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1 transition-all hover:bg-slate-100 focus:bg-slate-100 active:bg-slate-100"
                      onClick={() => navigate("/user_modify/info")}
                    >
                      정보 변경
                    </li>
                    <li className="border-b" />
                    <li
                      role="menuitem"
                      className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-1 transition-all hover:bg-slate-100 focus:bg-slate-100 active:bg-slate-100"
                      onClick={() => navigate("/my_home/switch")}
                    >
                      서비스 계정
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
    </div>
  );
}
