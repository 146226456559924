import React, { useState } from "react";
import BtnSnsLogin from "../component/BtnSnsLogin";
import ConfirmValidationInput from "../component/InputTextfield";
import Layout from "../component/Layout";
import useAuth from "../hook/useAuth";
import regex from "../shared/regex";

function SignInPage() {
  const [loginInput, setLoginInput] = useState({ email: "", password: "" });
  const [isCheck, setIsCheck] = useState(false);
  const { login } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await login(loginInput.email, loginInput.password);
    } catch (error) {
      alert("로그인 실패: " + error.message);
    }
  };
  return (
    <Layout>
      <main className="flex flex-col items-center justify-center pt-10">
        <h2 className="text-center text-2xl font-semibold">로그인</h2>
        <p className="text-center text-gray-600 p-3">
          로그인 후 다양한 서비스를 이용할 수 있습니다.
        </p>
        <div className="w-full max-w-md p-8 space-y-4 bg-white rounded shadow-md mt-10">
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <ConfirmValidationInput
                label="아이디(이메일)"
                value={loginInput.email}
                setValue={setLoginInput}
                isCheck={isCheck}
                setIsCheck={setIsCheck}
                regexCheck={regex.email}
                defaultText=""
                errorText="이메일 형식으로 입력해주세요."
                field="email"
              />
            </div>
            <div>
              <ConfirmValidationInput
                label="비밀번호"
                value={loginInput.password}
                setValue={setLoginInput}
                isCheck={isCheck}
                setIsCheck={setIsCheck}
                defaultText=""
                errorText=""
                field="password"
                type="password"
              />
            </div>
            <div className="flex items-center justify-start">
              <input
                id="remember-me"
                type="checkbox"
                className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
              <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-gray-900"
              >
                로그인 유지
              </label>
            </div>
            <button
              type="submit"
              className="w-full py-2 px-4 bg-black text-white font-medium rounded-md hover:bg-gray-800"
            >
              로그인
            </button>
          </form>
        </div>
        <div className="text-center text-sm mt-10 font-semibold">
          <a
            href="#"
            className="hover:bg-gray-300 no-underline text-black rounded-full py-1.5 px-2"
          >
            아이디 | 비밀번호 찾기
          </a>
        </div>
        <div className="text-center text-sm text-gray-500 mt-5">
          SNS 계정으로 가입하셨습니까?
        </div>
        <div className="w-1/3 border-b border-black m-4"></div>
        <div className="flex space-x-10 items-center justify-center text-center pb-8 mt-5">
          <BtnSnsLogin sns={"네이버"} className="h-8" />
          <BtnSnsLogin sns={"카카오"} className="h-8" />
        </div>
      </main>
    </Layout>
  );
}

export default SignInPage;
