import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/lawfin-logo.png";
import {
  BsFillQuestionCircleFill,
  BsExclamationCircleFill,
} from "react-icons/bs";

function Drawer({ onClose }) {
  const [isGeneralServiceOpen, setIsGeneralServiceOpen] = useState(false);
  const [isExpertServiceOpen, setIsExpertServiceOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div
        className="fixed inset-0 bg-black opacity-50 z-40"
        onClick={onClose}
      ></div>

      <div className="fixed left-0 top-0 w-2/3 h-full bg-white z-50 overflow-y-auto">
        <div className="border-b-2 border-gray-500 bg-bgColor pt-4">
          <div className="flex justify-center items-center">
            <img src={logo} alt="Logo" className="h-14" />
          </div>
          <div className="flex">
            <button className="w-1/2 bg-gray-200 rounded-full py-2 mx-2 mb-4">
              회원가입
            </button>
            <button className="w-1/2 border rounded-full py-2 mx-2 mb-4">
              로그인
            </button>
          </div>
        </div>

        <div className="font-bold">
          <div className="border-b-2 border-gray-200">
            <div
              className="flex justify-between items-center cursor-pointer py-3 px-3"
              onClick={() => setIsGeneralServiceOpen(!isGeneralServiceOpen)}
            >
              <span>일반인 서비스</span>
              <span>{isGeneralServiceOpen ? "▲" : "▼"}</span>
            </div>
            {isGeneralServiceOpen && (
              <div className="m-2 flex space-x-4 justify-center">
                <button className="bg-gray-100 rounded-full py-1 px-2 h-20 w-20">
                  상담신청
                </button>
                <button className="bg-gray-100 rounded-full py-1 px-2 h-20 w-20">
                  내용증명
                </button>
              </div>
            )}
          </div>

          <div className="border-b-2 border-gray-200">
            <div
              className="flex justify-between items-center cursor-pointer py-3 px-3"
              onClick={() => setIsExpertServiceOpen(!isExpertServiceOpen)}
            >
              <span>전문가 서비스</span>
              <span>{isExpertServiceOpen ? "▲" : "▼"}</span>
            </div>
            {isExpertServiceOpen && (
              <div className="m-2 space-x-4 justify-center">
                <div className="flex justify-center space-x-4 pb-3">
                  <button className="bg-gray-100 rounded-full py-1 px-2 h-20 w-20">
                    상담사건
                  </button>
                  <button className="bg-gray-100 rounded-full py-1 px-2 h-20 w-20">
                    보수표관리
                  </button>
                  <button className="bg-gray-100 rounded-full py-1 px-2 h-20 w-20">
                    사건관리
                  </button>
                </div>
                <div className="flex flex-col m-2 space-y-4">
                  <span>의뢰인 관리</span>
                  <span>파트너 수익배분 관리</span>
                  <span>운영 통계</span>
                  <span>전문가 등록</span>
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col space-y-4 px-3 py-3 cursor-pointer border-b-2 border-gray-200">
            <span onClick={() => navigate("/normal_guide")}>
              일반인 서비스 안내
            </span>
            <span onClick={() => navigate("/prof_guide")}>
              전문가 서비스 안내
            </span>
            <span>이용방법</span>
            <span>공지사항</span>
            <span>업데이트 안내</span>
            <span>1:1 고객센터</span>
            <span>회사 소개</span>
          </div>
          <div className="px-3 py-3 cursor-pointer">
            <span>전문가 서비스 메뉴얼</span>
          </div>
        </div>
      </div>
    </>
  );
}

function BottomNav() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <div className="flex flex-col justify-between z-30">
      {isDrawerOpen && <Drawer onClose={handleDrawerClose} />}

      <div className="fixed bottom-0 w-full bg-white shadow-lg">
        <div className="flex justify-around py-2">
          <button
            className="flex flex-col items-center text-gray-600"
            onClick={() => setIsDrawerOpen(true)}
          >
            <FaBars size={20} />
            <span className="text-sm">메뉴</span>
          </button>
          <button className="flex flex-col items-center text-gray-600">
            <BsExclamationCircleFill size={20} />
            <span className="text-sm">서비스 소개</span>
          </button>
          <button className="flex flex-col items-center text-gray-600">
            <BsFillQuestionCircleFill size={20} />
            <span className="text-sm">이용방법</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default BottomNav;
