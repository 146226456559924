import React from "react";
import Layout from "../component/Layout";
import Carousel from "../component/Carousel";
import { FaRegSmile } from "react-icons/fa";
import { IoMdPeople } from "react-icons/io";
import { HiOutlineEmojiSad } from "react-icons/hi";
import { MdSentimentSatisfied } from "react-icons/md";
import { BiSolidMessage } from "react-icons/bi";

export default function HomePage() {
  return <Layout fullWidth={false} children={Sample()}></Layout>;
}

function Sample() {
  return (
    <div className="flex flex-col tabletS:container mx-auto">
      <Carousel className="flex-row" />
      <MainList />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
        {Option1()}
        {Option2()}
        {Option3()}
        {Option4()}
      </div>
      <IconButtons />
    </div>
  );
}

function MainList() {
  const normalList = [
    { img: "/mainIcon/main-icon-myhome.png", menu: "마이 홈" },
    { img: "/mainIcon/main-icon-brief-state.png", menu: "사건진행 현황" },
    { img: "/mainIcon/main-icon-estimate-client-gray.png", menu: "상담신청" },
    {
      img: "/mainIcon/main-icon-certification-client-gray.png",
      menu: "내용증명",
    },
    { img: "/mainIcon/main-icon-counsel-client-gray.png", menu: "사건사례" },
  ];

  return (
    <div className="grid grid-cols-3 tabletS:grid-cols-5 bg-white shadow-xl content-center justify-around mt-10 p-3">
      {normalList.map((e, idx) => {
        return (
          <div
            className="flex flex-col items-center justify-center text-center px-2 py-2"
            key={idx}
          >
            <img
              src={process.env.PUBLIC_URL + e.img}
              className="p-2 w-16 tabletS:w-24 h-auto"
            ></img>
            <p className="text-xs md:text-sm w-1/2">{e.menu}</p>
          </div>
        );
      })}
    </div>
  );
}

//내용증명 작성
function Option1() {
  return (
    <div className="bg-white p-10">
      <div className="flex justify-between">
        <div className="font-bold">
          <p className="text-lg">내용증명 무료 자동작성</p>
          <p className="text-sm font-semibold">
            쉽고 빠르게 무료로 내용증명을 보내세요.
          </p>
        </div>
        <img
          src={
            process.env.PUBLIC_URL +
            "/mainIcon/main-icon-certification-client.png"
          }
          className="w-20 h-auto tabletS:w-28"
        />
      </div>
      <div className="grid grid-cols-3 tabletS:grid-cols-5 gap-1 mt-3">
        <Tag title="금전 채권" />
        <Tag title="부동산 매매" />
        <Tag title="임대차계약" />
        <Tag title="상품거래" />
        <Tag title="손해보상" />
      </div>
    </div>
  );
}

//내용증명 작성
function Option2() {
  return (
    <div className="bg-white p-10">
      <div className="flex justify-between">
        <div className="font-bold">
          <p className="text-lg">법률사건 예상견적서</p>
          <p className="text-sm font-semibold">
            공과금과 변호사 · 법무사 예상 보수액을 미리 확인하세요.
          </p>
        </div>
        <img
          src={
            process.env.PUBLIC_URL + "/mainIcon/main-icon-estimate-client.png"
          }
          className="w-20 h-auto tabletS:w-28"
        />
      </div>
      <div className="grid grid-cols-3 tabletS:grid-cols-5 gap-1 mt-3">
        <Tag title="소송사건" />
        <Tag title="가사" />
        <Tag title="형사" />
        <Tag title="행정" />
        <Tag title="신청사건" />
        <Tag title="민사집행" />
        <Tag title="회생파산" />
        <Tag title="공탁" />
        <Tag title="부동산등기" />
        <Tag title="법인등기" />
      </div>
    </div>
  );
}

function Option3() {
  return (
    <div className="bg-white p-10 h-fit items-center">
      <div className="flex justify-between">
        <div>
          <p className="font-bold text-lg"> 전문가 사무소 등록</p>
          <p className="font-semibold text-sm">
            등록 즉시 전문가서비스를 무료로 이용하실 수 있습니다.
          </p>
        </div>
        <FaRegSmile color="#40bfc0" size={45} className="self-center" />
      </div>
    </div>
  );
}

function Option4() {
  return (
    <div className="bg-white p-10 h-fit items-center">
      <div className="flex justify-between">
        <div>
          <p className="font-bold text-lg">소속원 등록</p>
          <p className="font-semibold text-sm">
            소속원 수의 제한없이 사무소에 등록할 수 있습니다.
          </p>
        </div>
        <IoMdPeople color="#40bfc0" size={45} className="self-center" />
      </div>
    </div>
  );
}

function Tag({ title }) {
  return (
    <div className="bg-gray-200 rounded-full text-center font-bold text-xs py-1">
      {title}
    </div>
  );
}

function IconButtons() {
  return (
    <div className="flex space-x-5 my-5 self-end text-xs justify-center items-center text-center font-semibold mr-4">
      <div className="flex-col">
        <div className="rounded-full bg-white p-2">
          <HiOutlineEmojiSad size={30} />
        </div>
        <span>오류 신고</span>
      </div>
      <div className="flex-col">
        <div className="rounded-full bg-white p-2">
          <MdSentimentSatisfied size={30} />
        </div>
        <span>기능요청</span>
      </div>
      <div className="flex-col">
        <div className="rounded-full bg-white p-2">
          <BiSolidMessage color="#f9e000" size={30} />
        </div>
        <span>카카오톡</span>
      </div>
      <div className="flex-col">
        <div className="rounded-full bg-white p-2">
          <BiSolidMessage color="#2DB400" size={30} />
        </div>
        <span>네이버톡</span>
      </div>
    </div>
  );
}
