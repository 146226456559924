import React, { useState, useEffect } from "react";

export default function ServiceGuideBanner({ type }) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  if (type == "normal") {
    return (
      <>
        {width >= 640 ? (
          <div className="bg-[#60bc7c] w-full min-h-[30vh] flex items-center justify-center text-white shadow-inner text-lg">
            <h1 className="inline-block font-extrabold text-4xl">
              일반인 서비스
            </h1>
            <div className="inline-block border-l-2 border-white h-14 mx-4"></div>
            <span className="inline-block font-extrabold">
              혼자 고민하지 말고, 로팀과 함께!
              <br />
              상담 · 견적 · 의뢰 까지 한번에 해결하세요.
            </span>
          </div>
        ) : (
          <div className="flex flex-col justify-center text-left items-left bg-[#60bc7c] w-full min-h-[20vh] text-white shadow-inner text-lg pl-5">
            <h1 className="font-extrabold text-sm pb-2">일반인 서비스</h1>
            <span className="font-extrabold">
              혼자 고민하지 말고, 로팀과 함께!
              <br />
              상담 · 견적 · 의뢰 까지 한번에 해결하세요.
            </span>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {width >= 640 ? (
          <div className="bg-[#80549c] w-full min-h-[30vh] flex items-center justify-center text-white shadow-inner text-lg">
            <h1 className="inline-block font-extrabold text-4xl">
              전문가 서비스
            </h1>
            <div className="inline-block border-l-2 border-white h-14 mx-4"></div>
            <span className="inline-block font-extrabold">
              사건에 집중하세요.
              <br />
              관리업무는 로팀이 해결하겠습니다!
            </span>
          </div>
        ) : (
          <div className="flex flex-col justify-center text-left items-left bg-[#80549c] w-full min-h-[20vh] text-white shadow-inner text-lg pl-10">
            <h1 className="font-extrabold text-sm pb-2 text-lg">
              전문가 서비스
            </h1>
            <span className="font-extrabold">
              사건에 집중하세요.
              <br />
              관리업무는 로팀이 해결하겠습니다!
            </span>
          </div>
        )}
      </>
    );
  }
}
