import Layout from "../component/Layout";
import ServiceGuideBanner from "../component/ServiceGuideBanner";
import ServiceGuideDescription from "../component/ServiceGuideDescription";

export default function Guide({ type }) {
  const normalList = [
    {
      title: "무료 견적 상담",
      content: `법률 전문가의 도움이\n필요하다면 "무료 견적상담"`,
      follow: `체크리스트 작성만으로 법률전문가 상담과\n소송견적서를 확인할 수 있어요.`,
      img: "/normal/normal-1.svg",
    },
    {
      title: "견적 자동 계산",
      content: `로팀의\n차별적인 "견적 시스템"`,
      follow: `대법원과 법무사협회규정에 따라 자동 계산된 전문가\n보수액을 즉시 확인할 수 있어요!`,
      img: "/normal/normal-2.svg",
    },
    {
      title: "사건 진행 현황",
      content: `사건 진행 과정을\n쉽게 확인할 수 있어요.`,
      follow: "대법원의 나의 사건 진행 정보를 빠르게 확인할 수 있어요.",
      img: "/normal/normal-3.svg",
    },
    {
      title: "내용증명 작성",
      content: `누구나 "쉽고 빠르게"\n내용증명을 작성할 수 있어요.`,
      follow: `금전거래, 부동산 계약 등의 분쟁이 발행했을 때 간단하게\n내용증명을 무료로 작성할 수 있어요.`,
      img: "/normal/normal-4.svg",
    },
    {
      title: "전문가 평가",
      content: `마무리까지 확실하게!\n전문가 평가`,
      follow: `다른 의뢰인들의 사건위임 후기를 확인하고,\n나의 전문가를 평가할 수 있어요.`,
      img: "/normal/normal-5.svg",
    },
  ];

  const profList = [
    {
      title: "사건관리",
      content: `사건을 빠르게 등록하고\n효율적으로 관리하세요.`,
      follow: `사건의 모든 상황을 정확하게 파악하고 관리하세요.\n사건별 담당자 · 접근권한 · 수익금액 · 배당률 등을 관리할 수 있어요.`,
      img: "/prof/expert-1.svg",
    },
    {
      title: "커뮤니케이션",
      content: `의뢰인과 필요 정보를\n편하게 공유하세요.`,
      follow: `의뢰인에게 대법원 사건진행 정보를 자동으로 안내하고,\n자료를 쉽게 공유할 수 있어요.`,
      img: "/prof/expert-2.svg",
    },
    {
      title: "사건관리",
      content: `법원의 기일과 사건정보를\n자동으로 확인하세요.`,
      follow:
        "대법원 사건정보를 자동으로 업데이트하고 사건 담당자에게\n사건진행정보와 기일정보를 자동으로 안내해요.",
      img: "/prof/expert-3.svg",
    },
    {
      title: "사무실 운영 관리",
      content: `전문가의 사건외 관리업무를\n효율적으로 운영할 수 있어요.`,
      follow: `수임료 관리 · 파트너 배당등의 관리와\n사무소 재원 관리가 투명하고 쉬워져요.`,
      img: "/prof/expert-4.svg",
    },
    {
      title: "보수표 관리",
      content: `사건수임료 관리 업무를\n누구나 정확하게 처리할 수 있어요.`,
      follow: `사건에 대한 수임료를 사건별로 설정할 수 있어요.`,
      img: "/prof/expert-5.svg",
    },
    {
      title: "사건사례",
      content: `사건 사례를 공유하고\n주요 업무를 알려주세요.`,
      follow: `종결된 사건을 등록하고\n전문가의 다양한 수행 업무를 알리세요.`,
      img: "/prof/expert-6.svg",
    },
    {
      title: "전문가 페이지",
      content: `브랜딩도 어렵지 않아요.`,
      follow: `전문 분야, 연락처, 해결사건,\n의뢰인의 평가등을 일반인에게 알려주세요.`,
      img: "/prof/expert-7.svg",
    },
  ];

  return (
    <Layout>
      <main className="flex flex-col items-center justify-center text-nowrap">
        <ServiceGuideBanner type={type} />
        {type === "normal"
          ? normalList.map((e, idx) => (
              <ServiceGuideDescription
                type={idx % 2 === 0 ? "right" : "left"}
                title={e.title}
                content={e.content}
                follow={e.follow}
                img={e.img}
              />
            ))
          : profList.map((e, idx) => (
              <ServiceGuideDescription
                type={idx % 2 === 0 ? "right" : "left"}
                title={e.title}
                content={e.content}
                follow={e.follow}
                img={e.img}
              />
            ))}
      </main>
    </Layout>
  );
}
