import React, { useState } from "react";
import {
  FormHelperText,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function ConfirmValidationInput({
  label,
  type = "text",
  value,
  maxValue,
  setValue,
  regexCheck,
  errorText,
  defaultText,
  field,
  valid,
  setIsValid,
  password,
  setError,
  required = true,
}) {
  const [isError, setIsError] = useState(valid);
  const [helperText, setHelperText] = useState(defaultText);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const HandleOnChange = (e) => {
    if (maxValue && maxValue < e.target.value.length) return;

    const value = e.target.value;
    const hasNonNumeric = /[^0-9]/.test(value);

    if (hasNonNumeric && type === "tel") {
      return;
    }

    setValue((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));

    if (
      setIsValid &&
      field === "passwordCheck" &&
      password !== e.target.value
    ) {
      setIsError(true);
      setHelperText("비밀번호가 일치하지 않습니다.");
      setIsValid((prev) => ({ ...prev, [field]: false }));
    } else if (setIsValid && field === "infoPageLink") {
      setIsValid(false);
    } else if (setIsValid) {
      const isValidInput = regexCheck ? regexCheck.test(e.target.value) : true;
      setIsError(!isValidInput);
      setHelperText(isValidInput ? defaultText : errorText);
      setIsValid((prev) => ({ ...prev, [field]: isValidInput }));
    }
  };

  if (type === "password") {
    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel
          color={{ isError } ? "black" : "error"}
          error={isError}
          required={required}
        >
          {label}
        </InputLabel>
        <OutlinedInput
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label={label}
          error={isError}
          helperText={helperText}
          onChange={HandleOnChange}
          value={value}
          required={required}
          color="black"
        />
        <FormHelperText error={isError}>{helperText}</FormHelperText>
      </FormControl>
    );
  } else {
    return (
      <TextField
        fullWidth
        label={label}
        error={setError == null ? isError : setError}
        helperText={helperText}
        type={type}
        onChange={HandleOnChange}
        value={value}
        required={required}
        color="black"
      />
    );
  }
}
