import { jwtDecode } from "jwt-decode";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import apiClient from "../services/apiClitent";
import { loginSuccess, logout } from "./authReducer";
const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = useCallback(() => {
    localStorage.removeItem("accessToken");
    apiClient.defaults.headers.common["Authorization"] = null;
    dispatch(logout());
  }, []);

  const login = useCallback(
    async (email, password) => {
      const response = await apiClient.post("/auth/login", {
        email,
        password,
      });

      const { accessToken } = response.data;
      localStorage.setItem("accessToken", accessToken);
      apiClient.defaults.headers.common["Authorization"] =
        `Bearer ${accessToken}`;

      const decodedToken = jwtDecode(accessToken);
      dispatch(loginSuccess(decodedToken));

      navigate("/");
    },
    [navigate]
  );

  const signUp = useCallback(
    async (name, email, tel, password, promotionPermit) => {
      const response = await apiClient.post("/auth/signup", {
        name,
        email,
        tel,
        password,
        promotionPermit,
      });
      alert("회원가입 성공");

      login(email, password);

      navigate("/");
    },
    [navigate]
  );

  return { login, logout, signUp };
};

export default useAuth;
