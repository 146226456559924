import { useState, useRef } from "react";
import ConfirmValidationInput from "../component/InputTextfield";
import Layout from "../component/Layout";
import { FaCameraRotate } from "react-icons/fa6";
import regex from "../shared/regex";
import Switch from "@mui/material/Switch";

export default function ChangePersonalInfoPage() {
  const [pw, setPw] = useState({ password: "" });
  const [isPwChecked, setIsPwChecked] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log(pw);
      setIsPwChecked(true);
    } catch (error) {
      alert("비밀번호가 일치하지 않습니다 " + error.message);
    }
  };

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Layout
      children={
        isPwChecked ? (
          <ChangeInfo
            handleIconClick={handleIconClick}
            handleFileChange={handleFileChange}
            fileInputRef={fileInputRef}
            previewImage={previewImage}
          />
        ) : (
          <CheckPassword
            pw={pw.password}
            setPw={setPw}
            handleSubmit={handleSubmit}
          />
        )
      }
    ></Layout>
  );
}

const ChangeInfo = ({
  handleIconClick,
  handleFileChange,
  fileInputRef,
  previewImage,
}) => {
  return (
    <div className="flex flex-col items-center mt-12">
      <div className="h-32 w-32 items-end justify-end flex">
        <div className="rounded-full overflow-hidden w-32 h-32 border-4 border-white">
          <img
            src={previewImage || process.env.PUBLIC_URL + "/user/user.png"}
            alt="User Avatar"
            className="z-0"
          />
        </div>
        <div className="z-10 absolute">
          <FaCameraRotate size={35} className="" onClick={handleIconClick} />
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleFileChange}
            className="hidden"
          />
        </div>
      </div>
      <div className="space-y-3 text-center mt-4">
        <strong>test@lawalliance.co.kr</strong>
        <p className="text-sm">010-1234-5678</p>
      </div>
      <div className="border w-full max-w-md p-8 mt-10 space-y-4">
        <ConfirmValidationInput label={"이름"} required={false} />
        <div className="flex space-x-4 items-center">
          <ConfirmValidationInput
            label="핸드폰 번호"
            maxValue={11}
            regexCheck={regex.tel}
            defaultText="핸드폰 번호 입력 후 인증요청을 클릭하세요."
            errorText="핸드폰 번호를 올바르게 입력하세요."
            field="tel"
            required={false}
          />
          <button
            type="button"
            className="flex-none rounded-full border border-gray-400 px-3 text-sm my-4 h-fit py-2 bg-white"
          >
            인증
          </button>
        </div>
        <ConfirmValidationInput
          label="비밀번호"
          regexCheck={regex.password}
          defaultText="영문 · 숫자 · 기호를 조합하여 8자 이상으로 입력해주세요."
          errorText="영문 · 숫자 · 기호를 조합하여 8자 이상으로 입력해주세요."
          field="password"
          type="password"
          required={false}
        />
        <ConfirmValidationInput
          label="비밀번호 확인"
          defaultText="비밀번호를 한번 더 입력해주세요."
          errorText="비밀번호가 일치하지 않습니다."
          field="passwordCheck"
          type="password"
          required={false}
        />
        <div className="border-b-2 border-dotted border-gray-600" />
        <div className="flex flex-col">
          <strong className="my-5">정보 수신 설정</strong>

          <div className="flex items-center">
            <Switch />
            <p>이메일 수신</p>
          </div>
          <div className="flex items-center">
            <Switch />
            <p>SNS 수신</p>
          </div>
          <div className="flex items-center pb-3">
            <Switch />
            <p>푸시알림 수신</p>
          </div>
          <div className="border-b-2 border-dotted border-gray-600" />
          <div className="border-b-2 border-dotted border-gray-600 pt-2" />
          <div className="flex items-center py-6">
            <Switch />
            <p>이벤트 및 프로모션 알림 수신</p>
          </div>
        </div>
      </div>
      <div className="flex w-full max-w-md space-x-4 py-6">
        <button className="rounded-full text-white bg-red-600 font-bold flex-1 py-1.5 hover:bg-red-800">
          회원 탈퇴
        </button>
        <button className="rounded-full border bg-white font-bold flex-1 hover:bg-gray-100">
          취소
        </button>
        <button className="rounded-full text-white bg-gray-800 font-bold flex-1 hover:bg-gray-950">
          저장
        </button>
      </div>
    </div>
  );
};

const CheckPassword = ({ pw, setPw, handleSubmit }) => {
  return (
    <div className="flex flex-col items-center pt-24 space-y-4">
      <h1 className="text-2xl font-bold">비밀번호 확인</h1>
      <span>정보보호를 위해 비밀번호를 입력하세요.</span>
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
        <form className="flex items-center space-x-4" onSubmit={handleSubmit}>
          <ConfirmValidationInput
            type="password"
            label={"현재 비밀번호"}
            value={pw}
            setValue={setPw}
            field={"password"}
          />
          <button
            className="border border-gray-500 rounded-full px-5 h-10 text-black hover:bg-pointLight hover:text-white hover:border-pointLight"
            type="submit"
          >
            확인
          </button>
        </form>
      </div>
    </div>
  );
};
