import BtnSnsLogin from "../component/BtnSnsLogin";
import ConfirmValidationInput from "../component/InputTextfield";
import Layout from "../component/Layout";
import { useState, useEffect } from "react";
import regex from "../shared/regex";
import { FormGroup } from "@mui/material";
import useAuth from "../hook/useAuth";

function SignUpPage() {
  const [isFormValid, setIsFormValid] = useState(false);
  const { signUp } = useAuth();
  const [signUpInput, setSignUpInput] = useState({
    name: "",
    email: "",
    tel: "",
    password: "",
    passwordCheck: "",
    promotionCheck: false,
  });

  const [validationState, setValidationState] = useState({
    name: false,
    email: false,
    tel: false,
    password: false,
    passwordCheck: false,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isFormValid) return;

    console.log("회원가입 시도: ", signUpInput);

    signUp(
      signUpInput.name,
      signUpInput.email,
      signUpInput.tel,
      signUpInput.password,
      signUpInput.promotionCheck
    );
  };

  useEffect(() => {
    const allFieldsValid = Object.values(validationState).every(
      (isValid) => isValid === true
    );
    setIsFormValid(allFieldsValid);
  }, [validationState]);

  return (
    <Layout>
      <main className="flex flex-col items-center justify-center p-10">
        <SignUpHeader />
        <div className="w-1/3 border-b border-black m-4"></div>
        <div className="flex space-x-10 items-center justify-center text-center pb-8">
          <BtnSnsLogin sns={"네이버"} className="h-8" />
          <BtnSnsLogin sns={"카카오"} className="h-8" />
        </div>
        <div className="w-full max-w-md p-8 bg-white rounded shadow-md">
          <form className="space-y-4" onSubmit={handleSubmit}>
            {/** 이름 입력 필드 **/}
            <ConfirmValidationInput
              label="이름"
              value={signUpInput.name}
              setValue={setSignUpInput}
              regexCheck={regex.name}
              defaultText=""
              errorText="한글 두글자 이상 입력해주세요."
              field="name"
              valid={validationState.name}
              setIsValid={setValidationState}
            />
            {/** 이메일 입력 필드 **/}
            <ConfirmValidationInput
              label="아이디(이메일)"
              value={signUpInput.email}
              setValue={setSignUpInput}
              regexCheck={regex.email}
              defaultText=""
              errorText="이메일 형식으로 입력해주세요."
              field="email"
              valid={validationState.email}
              setIsValid={setValidationState}
            />
            {/** 핸드폰 번호 입력 필드 **/}
            <div className="flex space-x-4">
              <ConfirmValidationInput
                label="핸드폰 번호"
                value={signUpInput.tel}
                setValue={setSignUpInput}
                maxValue={11}
                regexCheck={regex.phone}
                defaultText="핸드폰 번호 입력 후 인증요청을 클릭하세요."
                errorText="핸드폰 번호를 올바르게 입력하세요."
                field="tel"
                valid={validationState.tel}
                setIsValid={setValidationState}
                type="tel"
              />
              <button
                type="button"
                className="flex-none rounded-full border px-3 text-sm my-4 h-fit py-2"
              >
                인증
              </button>
            </div>
            {/** 비밀번호 입력 필드 **/}
            <ConfirmValidationInput
              label="비밀번호"
              value={signUpInput.password}
              setValue={setSignUpInput}
              regexCheck={regex.password}
              defaultText="영문 · 숫자 · 기호를 조합하여 8자 이상으로 입력해주세요."
              errorText="영문 · 숫자 · 기호를 조합하여 8자 이상으로 입력해주세요."
              field="password"
              type="password"
              valid={validationState.password}
              setIsValid={setValidationState}
            />
            {/** 비밀번호 확인 입력 필드 **/}
            <ConfirmValidationInput
              label="비밀번호 확인"
              value={signUpInput.passwordCheck}
              setValue={setSignUpInput}
              defaultText="비밀번호를 한번 더 입력해주세요."
              errorText="비밀번호가 일치하지 않습니다."
              field="passwordCheck"
              type="password"
              valid={validationState.passwordCheck}
              setIsValid={setValidationState}
              password={signUpInput.password}
            />
            <Agreement
              promotionCheck={signUpInput.promotionCheck}
              setSignUpInput={setSignUpInput}
            />
            <button
              type="submit"
              className={`w-full py-2 ${
                isFormValid ? "bg-black" : "bg-gray-300"
              } text-white rounded`}
              disabled={!isFormValid}
            >
              회원가입
            </button>
          </form>
          <p className="round-full text-sm text-center hover:bg-gray-100 mt-5 py-2">
            이미 회원이신가요?
          </p>
        </div>
      </main>
    </Layout>
  );
}

const SignUpHeader = () => {
  return (
    <>
      <h3 className="text-2xl mb-4 ">회원가입</h3>
      <p className="text-sm text-center font-semibold">
        전문가 회원도 무료 회원가입이 필요합니다.
        <br />
        로그인 후 전문가 등록 또는 소속원 신청 후에 전문가서비스를 이용하실 수
        있습니다.
      </p>
      <p className="flex mt-10 text-sm">SNS 계정으로 회원가입</p>
    </>
  );
};

const Agreement = ({ promotionCheck, setSignUpInput }) => {
  const [agreeAll, setAgreeAll] = useState(false);
  const [agreeService, setAgreeService] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [agreePromo, setAgreePromo] = useState(false);

  const handleAgreeAllChange = () => {
    const newAgreeAllState = !agreeAll;
    setAgreeAll(newAgreeAllState);
    setAgreeService(newAgreeAllState);
    setAgreePrivacy(newAgreeAllState);
    setAgreePromo(newAgreeAllState);
    setSignUpInput((prevState) => ({
      ...prevState,
      ["promotionCheck"]: newAgreeAllState,
    }));
  };

  const handleIndividualChange = (setter, value) => {
    setter(!value);
    setter === setAgreePromo &&
      setSignUpInput((prevState) => ({
        ...prevState,
        ["promotionCheck"]: !value,
      }));

    setAgreeAll((prevState) => {
      const newAgreeService =
        setter === setAgreeService ? !value : agreeService;
      const newAgreePrivacy =
        setter === setAgreePrivacy ? !value : agreePrivacy;
      const newAgreePromo = setter === setAgreePromo ? !value : agreePromo;

      return newAgreeService && newAgreePrivacy && newAgreePromo;
    });
  };

  return (
    <FormGroup>
      <div className="flex items-center py-3">
        <input
          id="agree-all"
          type="checkbox"
          checked={agreeAll}
          onChange={handleAgreeAllChange}
          className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
        />
        <label htmlFor="agree-all" className="ml-2 block text-sm text-gray-900">
          전체 동의합니다.
        </label>
      </div>
      <div class="border-dashed border-t-2 border-gray-500 pb-3"></div>
      <div className="flex items-center pb-3 justify-between">
        <div className="flex">
          <input
            id="agree-service"
            type="checkbox"
            checked={agreeService}
            onChange={() =>
              handleIndividualChange(setAgreeService, agreeService)
            }
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            required
          />
          <label
            htmlFor="agree-service"
            className="ml-2 block text-sm text-gray-900"
          >
            서비스 이용약관 동의 (필수)
          </label>
        </div>
        <button
          type="button"
          className="flex-none rounded-full border px-2 text-sm py-1"
        >
          내용보기
        </button>
      </div>
      <div className="flex items-center pb-3 justify-between">
        <div className="flex">
          <input
            id="agree-privacy"
            type="checkbox"
            checked={agreePrivacy}
            onChange={() =>
              handleIndividualChange(setAgreePrivacy, agreePrivacy)
            }
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            required
          />
          <label
            htmlFor="agree-privacy"
            className="ml-2 block text-sm text-gray-900"
          >
            개인정보 수집 및 이용 동의 (필수)
          </label>
        </div>
        <button
          type="button"
          className="flex-none rounded-full border px-2 text-sm py-1"
        >
          내용보기
        </button>
      </div>
      <div className="flex items-center justify-start pb-3">
        <input
          id="agree-promo"
          type="checkbox"
          checked={agreePromo}
          onChange={() => handleIndividualChange(setAgreePromo, agreePromo)}
          className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
        />
        <label
          htmlFor="agree-promo"
          className="ml-2 block text-sm text-gray-900"
        >
          이벤트 및 프로모션 안내알림 (선택)
        </label>
      </div>
    </FormGroup>
  );
};

export default SignUpPage;
