import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DialogMemberOnly from "./DialogMemberOnly";
import Layout from "./Layout";

export default function ProtectedRoute({ component: Component }) {
  const [hasPermission, setHasPermission] = useState(false); // 권한 여부
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.isAuthenticated) {
      setHasPermission(true);
    } else {
      setHasPermission(false);
      setIsModalOpen(true);
    }
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate("/");
  };

  return (
    <Layout>
      {hasPermission ? (
        <Component />
      ) : (
        <DialogMemberOnly isOpen={isModalOpen} onClose={handleCloseModal} />
      )}
    </Layout>
  );
}
