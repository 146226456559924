import React, { useState, useEffect } from "react";
import appStore from "../assets/images/store/AppStore.png";
import playStore from "../assets/images/store/GooglePlay.png";
import lawfin from "../assets/images/lawfin-logo-text-regist.png";
import { GoTriangleDown } from "react-icons/go";
import { useLocation } from "react-router-dom";

function Footer() {
  const [width, setWidth] = useState(window.innerWidth);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [show, setShow] = useState(false);
  const Legal = () => {
    if (show) {
      return (
        <div className="font-bold py-3 divide-y divide-dashed divide-slate-950">
          <p>
            로핀은 전문가회원 (변호사·법무사)의 사무관리 시스템만을 제공할 뿐,
            전문가회원이 개인회원(의뢰인)에게 제공하는 상담정보와 견적서 및
            일반인의 전문가 선택 등에 어떠한 관여도 하지 않습니다. 모든 전문가
            업무는 전문가회원이 직접 작성한 정보로서 로핀은 법률 서비스 및 업무
            당사자가 아닙니다.
            <br />
            전문가회원의 등록정보 (변호사등록증 · 법무사등록증 · 배상책임보험
            정보)는 특정 개인정보를 제외하고 수정없이 표시하고 있습니다.
            전문가회원이 직접 제공한 정보는 무단으로 복제, 편집, 전시, 배포 등에
            이용할 수 없습니다.
          </p>
          <div />
        </div>
      );
    } else {
      return;
    }
  };

  if (location.pathname != "/") {
    return (
      <footer className="bg-white font-bold text-xs mb-12">
        <div className="text-xs py-2 text-center">
          Copyright © 2015 - 2024 로핀 LAWFIN®
        </div>
      </footer>
    );
  }

  if (width >= 640) {
    return (
      <footer className="bg-white p-4 font-bold ">
        <div className="container mx-auto">
          <div className="flex justify-between">
            <p
              onClick={() => setShow(!show)}
              className="flex items-center space-x-1 font-semibold w-2/3 text-sm"
            >
              로핀은 법률상담 등 직접적인 법률서비스를 제공하지 않으며 변호사법
              및 관련 규정을 준수하고 있습니다. <GoTriangleDown />
            </p>
            <div className="flex space-x-4">
              <a
                className="store-link"
                href="https://apps.apple.com/kr/app/%EB%A1%9C%ED%95%80-law-fin/id1367038224"
              >
                <img className="store-img" src={appStore} width={"140px"} />
              </a>
              <a
                className="store-link"
                href="https://play.google.com/store/apps/details?id=kr.co.lawalliance.lawcost"
              >
                <img className="store-img" src={playStore} width={"140px"} />
              </a>
            </div>
          </div>
          <Legal />
          <div className="text-xs text-gray-400 pt-3">
            <p>
              (주) 로얼라이언스 ㅣ 대표 김대훈 ㅣ 사업자번호 522-81-00931 ㅣ
              통신판매번호 2018-서울강남-00730 <br />
              개인정보관리책임자 김대훈ㅣ분쟁조정기관표시 전자거래분쟁조정위원회
              <br />
              서울 강남구 영동대로76길 10 2층 로얼라이언스 ㅣ
              lawfin@lawalliance.co.kr ㅣ 전화 02-555-0324
            </p>
          </div>

          <div className="text-xs  py-2">
            <a>이용약관</a>
            <a>ㅣ 개인정보 취급·처리 방침 ㅣ</a>
            <a>이메일 무단수집거부</a>
          </div>

          <div className="flex place-content-start h-4 my-3">
            <img src={lawfin} className="place-self-center h-4" />
            <div className="text-sm ">은 로얼라이언스의 등록상표입니다.</div>
          </div>

          <div className="text-sm py-2">
            Copyright © 2015 - 2024 로핀 LAWFIN®LawAlliance Co.,Ltd
          </div>
        </div>
      </footer>
    );
  } else {
    return (
      <footer className="bg-white font-bold text-xs mb-20">
        <div className="mx-auto p-1 space-y-5">
          <div className="flex">
            <p onClick={() => setShow(!show)} className="font-bold">
              로핀은 법률상담 등 직접적인 법률서비스를 제공하지 않으며 변호사법
              및 관련 규정을 준수하고 있습니다.
            </p>
            <GoTriangleDown size={30} />
          </div>
          <Legal />
          <div className="text-xs text-gray-400">
            <p>
              (주) 로얼라이언스 ㅣ 대표 김대훈 ㅣ 사업자번호 522-81-00931
              <br />
              통신판매번호 2018-서울강남-00730 <br />
              개인정보관리책임자 김대훈ㅣ분쟁조정기관표시 전자거래분쟁조정위원회
              <br />
              서울 강남구 영동대로76길 10 2층 로얼라이언스
              <br />
              lawfin@lawalliance.co.kr
              <br /> 전화 02-555-0324
            </p>
          </div>

          <div className="text-xs py-2 text-center">
            <a>이용약관</a>
            <br />
            <a>개인정보 취급·처리 방침</a>
            <br />
            <a>이메일 무단수집거부</a>
          </div>

          <div className="flex justify-center place-content-start h-4 my-3">
            <img src={lawfin} className="place-self-center h-4" />
            <div className="text-xs">은 로얼라이언스의 등록상표입니다.</div>
          </div>

          <div className="text-xs py-2 text-center">
            Copyright © 2015 - 2024 로핀 LAWFIN®
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
