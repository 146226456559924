import axios from "axios";

const apiClient = axios.create({
  // baseURL: "https://api.lawfin.co.kr",
  baseURL: "http://localhost:8080",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

apiClient.interceptors.request.use(
  (config) => {
    const token = apiClient.defaults.headers.common["Authorization"];
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const { data } = await axios.get(
          "/auth/token",
          {},
          { withCredentials: true }
        );

        const newAccessToken = data.accessToken;
        apiClient.defaults.headers.common["Authorization"] =
          `Bearer ${newAccessToken}`;

        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return apiClient(originalRequest);
      } catch (refreshError) {
        console.error("토큰 갱신 실패:", refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
