import { useState, useRef } from "react";
import ConfirmValidationInput from "../component/InputTextfield";
import Layout from "../component/Layout";
import InputDropDown from "../component/InputDropDown";
import apiClient from "../services/apiClitent";
import { useNavigate } from "react-router-dom";

function ExpertContractPage() {
  const navigate = useNavigate();
  const [stage, setStage] = useState(1);
  const [basicInfo, setBasicInfo] = useState({
    name: "",
    tel: "",
    fax: "",
    add: "",
    addDetail: "",
    expertType: "",
    infoPageLink: "",
  });

  const [isError, setIsError] = useState({
    name: false,
    tel: false,
    add: false,
    expertType: false,
  });
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileUpload = async () => {
    const formData = new FormData();
    const imgLink = "";
    formData.append("images", selectedFile);

    try {
      const response = await apiClient.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("파일 업로드 성공:", response.data);
      imgLink = response.data;
    } catch (error) {
      console.error("파일 업로드 실패:", error);
    }

    try {
      const data = {
        name: basicInfo.name,
        tel: basicInfo.tel,
        fax: basicInfo.fax,
        address: basicInfo.add + " " + basicInfo.addDetail,
        type: basicInfo.expertType,
        certificateImg: imgLink,
        infoPageLink: basicInfo.infoPageLink,
      };
      const response = await apiClient.post("/expert", data);
      console.log(response.data);
      alert("전문가 등록 성공");
      navigate("/");
    } catch (error) {
      alert("전문가 등록 실패: ", error);
    }
  };

  const validateInputs = () => {
    const errors = {
      name: basicInfo.name === "",
      tel: basicInfo.tel === "",
      add: basicInfo.add === "",
      expertType: basicInfo.expertType === "",
    };
    setIsError(errors);
    return !Object.values(errors).some((error) => error);
  };

  const handleNext = () => {
    if (stage === 1) {
      if (validateInputs()) {
        setStage(stage + 1);
      } else {
        alert("모든 필드를 입력해주세요.");
      }
    } else if (stage === 2) {
      if (imagePreview === null) {
        alert("사업자 등록증을 업로드 해주세요.");
      } else {
        setStage(stage + 1);
      }
    } else {
      if (isConfirmed) {
        handleFileUpload();
      } else {
        alert("전문가 페이징 명을 입력해주세요.");
      }
    }
  };

  return (
    <div className="sm:container mx-auto flex flex-col my-16">
      <div className="justify-between flex">
        <div className="space-y-2">
          <strong className="text-2xl">전문가 등록</strong>
          <p className="text-sm font-semibold">
            전문가 등록 즉시 이용하실 수 있습니다.
          </p>
        </div>
        <button className="w-24 h-24 bg-pointLight hover:bg-pointDark text-white rounded-full font-bold">
          소속원 신청
        </button>
      </div>
      <div className="w-full p-8 mt-10 space-y-4 bg-white rounded">
        <div className="flex items-center space-x-3 text-xs tabletS:text-sm">
          <div
            className={`flex justify-center rounded-full w-10 h-10 ${
              stage === 1 ? "bg-pointDark" : "bg-gray3"
            }`}
          >
            <h5 className="self-center font-bold text-white">1</h5>
          </div>
          <strong>기본정보</strong>
          <div className="grow border-b-2 border-gray-500" />
          <div
            className={`flex justify-center rounded-full w-10 h-10 ${
              stage === 2 ? "bg-pointDark" : "bg-gray3"
            }`}
          >
            <h5 className="self-center font-bold text-white">2</h5>
          </div>
          <strong>사업자 정보</strong>
          <div className="grow border-b-2 border-gray-500" />
          <div
            className={`flex justify-center rounded-full w-10 h-10 ${
              stage === 3 ? "bg-pointDark" : "bg-gray3"
            }`}
          >
            <h5 className="self-center font-bold text-white">3</h5>
          </div>
          <strong>부가정보</strong>
        </div>
        {stage === 1 ? (
          <BasicInfo
            basicInfo={basicInfo}
            setBasicInfo={setBasicInfo}
            isError={isError}
          />
        ) : stage === 2 ? (
          <BusinessInfo
            fileInputRef={fileInputRef}
            handleFileChange={handleFileChange}
            imagePreview={imagePreview}
          />
        ) : (
          <AdditionalInfo
            basicInfo={basicInfo.infoPageLink}
            setBasicInfo={setBasicInfo}
            isConfirmed={isConfirmed}
            setIsConfirmed={setIsConfirmed}
          />
        )}
        <div className="flex justify-between font-bold">
          <button
            className="border rounded-full py-1.5 px-8 hover:bg-gray-100"
            onClick={() => {
              setStage(stage !== 1 ? stage - 1 : stage);
            }}
          >
            {stage === 1 ? "취소" : "이전"}
          </button>
          <button
            className={`rounded-full py-1.5 px-8 text-white ${
              stage === 3 ? "bg-pointDark" : "bg-gray3"
            } hover:bg-pointDark`}
            onClick={handleNext}
          >
            {stage === 3 ? "등록" : "다음"}
          </button>
        </div>
      </div>
    </div>
  );
}

const BasicInfo = ({ basicInfo, setBasicInfo, isError }) => {
  return (
    <>
      <div className="flex">
        <div className="sm:flex-1 p-3 border space-y-3">
          <ConfirmValidationInput
            label="전문가 명"
            value={basicInfo.name}
            setValue={setBasicInfo}
            field="name"
            setError={isError.name}
          />
          <ConfirmValidationInput
            label="사무소 연락처"
            value={basicInfo.tel}
            setValue={setBasicInfo}
            field="tel"
            setError={isError.tel}
          />
          <ConfirmValidationInput
            label="사무소 팩스"
            value={basicInfo.fax}
            setValue={setBasicInfo}
            field="fax"
            type="tel"
            required={false}
          />
          <div className="flex space-x-4 items-center">
            <ConfirmValidationInput
              label="사무소 주소"
              value={basicInfo.add}
              setValue={setBasicInfo}
              field="add"
              setError={isError.add}
            />
            <button
              type="button"
              className="flex-none rounded-full border border-gray-400 px-3 text-sm my-4 h-fit py-2 bg-white hover:bg-gray-100"
            >
              검색
            </button>
          </div>
          <ConfirmValidationInput
            label="상세주소"
            value={basicInfo.addDetail}
            setValue={setBasicInfo}
            field="addDetail"
            required={false}
          />
          <InputDropDown
            label={"전문가 종류"}
            value={basicInfo.expertType}
            setValue={setBasicInfo}
            field="expertType"
            setError={isError.expertType}
            menuItems={[
              "변호사 사무소",
              "합동 법무사 사무소",
              "합동 법률 사무소",
              "법무법인",
              "법무사 사무소",
              "법무팀",
              "회계사 사무소",
              "회계법인",
              "세무사 사무소",
              "세무법인",
              "공인중개사 사무소",
              "공인중개법인",
            ]}
          />
          <p className="text-pointDark font-bold">
            기본 정보는 전문가 등록 후에도 수정할 수 있습니다.
          </p>
        </div>
        <div className="flex-1"></div>
      </div>
    </>
  );
};

const BusinessInfo = ({ handleFileChange, fileInputRef, imagePreview }) => {
  return (
    <>
      <div className="flex flex-col tabletS:flex-row">
        <div className="flex-1 p-3 border space-y-3">
          <div className="flex justify-between items-center">
            <p className="font-bold text-sm">사업자 등록증</p>
            <button
              className="bg-pointLight hover:bg-pointDark rounded-full p-2 text-white text-sm font-bold"
              onClick={() => fileInputRef.current.click()}
            >
              파일 선택
            </button>
            <input
              type="file"
              accept="image/*"
              className="hidden"
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </div>
          <div className="border w-full m-3 mx-auto rounded p-2 text-sm">
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="미리보기"
                className="w-full h-auto"
              />
            ) : (
              <p>등록된 이미지가 없습니다.</p>
            )}
          </div>
          <p className="text-sm font-bold">
            사업자 등록증 또는 자격을 증명하는 문서를 첨부해주세요.
          </p>
        </div>
        <div className="tabletS:flex-1">
          <div className="space-y-2 text-sm font-bold p-3">
            <p>
              변호사 · 법무사 자격이 있는 회원만 상담요청정보를 확인할 수
              있습니다.
            </p>
            <p>등록 예 : 사업자등록증 · 변호사등록증 · 법무사등록증</p>
          </div>
          <div className="w-full m-3 p-2 py-4 rounded bg-bgColor text-sm space-y-2 font-bold">
            <p className="text-red-500">
              상담요청 메뉴는 관리자의 법률전문가 확인 및 승인 후에 이용하실 수
              있습니다.
            </p>
            <p>등록된 증명이 허위일 경우, 사용계정은 안내없이 차단됩니다.</p>
          </div>
        </div>
      </div>
    </>
  );
};

const AdditionalInfo = ({
  basicInfo,
  setBasicInfo,
  isConfirmed,
  setIsConfirmed,
}) => {
  const [validationMessage, setValidationMessage] = useState("");
  // const [isConfirmed, setIsConfirmed] = useState(false);

  const handleCheckDuplicate = async () => {
    try {
      const resp = await apiClient.get("/expert/info_page_link", {
        params: {
          infoPageLink: basicInfo,
        },
      });

      if (resp.data) {
        setBasicInfo((prevState) => ({
          ...prevState,
          ["infoPageLink"]: "",
        }));
        setValidationMessage("이미 존재하는 페이지명 입니다.");
        setIsConfirmed(false);
      } else {
        setValidationMessage("사용 가능한 페이지명입니다.");
        setIsConfirmed(true);
      }
    } catch (error) {
      console.error("중복 확인 중 오류 발생:", error);
    }
  };

  return (
    <>
      <div className="flex flex-col sm:flex-row">
        <div className="flex-1 p-3 border space-y-3">
          <p className="font-bold text-sm">전문가 정보페이지</p>
          <div className="flex space-x-4 items-center">
            <ConfirmValidationInput
              label="전문가 페이지 명"
              value={basicInfo}
              setValue={setBasicInfo}
              field="infoPageLink"
              valid={isConfirmed}
              setIsValid={setIsConfirmed}
            />
            <button
              type="button"
              className="flex-none rounded-full border border-gray-400 px-3 text-sm my-4 h-fit py-2 bg-white"
              onClick={handleCheckDuplicate}
            >
              중복확인
            </button>
          </div>
          {isConfirmed && (
            <p className="font-semibold text-sm text-blue-500">
              {validationMessage}
            </p>
          )}
        </div>
        <div className="flex-1">
          <div className="space-y-2 text-sm p-3">
            <p>
              입력된 전문가페이지 이름은{" "}
              <span className="font-bold">https://lawfin.co.kr/lawyer/</span>
              <span className="text-pointDark font-bold">전문가이름</span>
              으로 전문가정보 페이지 주소로 사용됩니다.
            </p>
            <p>
              전문가이름은 영문과 숫자로 사용할 수 있으며,
              <span className="text-warning font-bold">
                {" "}
                등록 후에는 변경할 수 없습니다.
              </span>
            </p>
            <p className="font-bold">입력 예 : lawyer.kimnkim.025550324</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpertContractPage;
