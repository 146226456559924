import Layout from "../component/Layout";
import { useSelector } from "react-redux";

export default function MyHomeSwitchPage() {
  const auth = useSelector((state) => state.auth);

  return (
    <Layout>
      <div className="tabletS:container mx-auto">
        <div className="tabletS:flex tabletS:justify-between my-10">
          <div className="flex flex-col space-y-4 text-center tabletS:text-start">
            <h2 className="text-2xl font-bold">서비스 계정</h2>
            <span className="font-semibold text-sm">
              여러 전문가 계정을 사용하실 수 있습니다.
            </span>
          </div>
          <div className="flex space-x-4 text-sm font-bold justify-center mt-5 tabletS:mt-0">
            <button className="bg-black text-white rounded-full w-20 h-20 hover:bg-pointDark">
              전문가 등록
            </button>
            <button className="bg-gray2 text-white rounded-full w-20 h-20 hover:bg-pointDark">
              소속원 신청
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 bg-white w-full h-96 rounded-lg">
          <div className="flex-col flex-1 border p-4 h-fit m-4 rounded-md space-y-4">
            <div className="text-center border-b-2 border-dotted border-black pb-4">
              <h5 className="font-semibold">일반인 계정</h5>
              <span className="text-sm">
                일반 회원 계정으로 서비스가 전환됩니다.
              </span>
            </div>
            <div className="flex justify-between">
              <div className="flex">
                <img
                  src={process.env.PUBLIC_URL + "/user/user.png"}
                  width={40}
                />
                <span className="font-bold self-center">{auth.user?.name}</span>
              </div>
              <button className="bg-pointLight hover:bg-pointDark text-white rounded-full px-4">
                계정 전환
              </button>
            </div>
          </div>
          <div className="flex-col flex-1 border p-4 h-fit m-4 rounded-md space-y-4">
            <div className="text-center border-b-2 border-dotted border-black pb-4">
              <h5 className="font-semibold">전문가 계정</h5>
              <span className="text-sm">등록된 전문가 계정이 없습니다.</span>
            </div>
            <div className="flex flex-1 justify-between text-center">
              {/* <div className="flex">
                <img
                  src={process.env.PUBLIC_URL + "/user/user.png"}
                  width={40}
                />
                <span className="font-bold">이은서</span>
              </div>
              <button className="bg-[#40bfc0] hover:bg-[#14b2ae] text-white rounded-full px-4">
                계정 전환
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
