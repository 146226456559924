import React, { useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";

export default function DialogMemberOnly({ isOpen, onClose }) {
  const dialogRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onClose();
      }
    };
    if (isOpen) {
      window.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return createPortal(
    <>
      <div className="fixed inset-0 bg-black/60 z-40"></div>
      <div
        ref={dialogRef}
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg z-50 w-1/3 py-5 shadow-lg"
      >
        <div className="flex flex-col py-3 px-5 place-items-center space-y-5">
          <h4 className="font-extrabold py-3 text-xl">회원 전용 서비스</h4>
          <p className="font-semibold">
            로그인 후 서비스를 이용할 수 있습니다.
          </p>
          <div className="flex w-full justify-center">
            <button
              className="bg-gray1 text-black rounded-full w-1/3 py-1 mx-3"
              onClick={() => navigate("/join")}
            >
              무료 회원가입
            </button>
            <button
              className="bg-black text-white rounded-full w-1/3 py-1 mx-3"
              onClick={() => navigate("/login")}
            >
              로그인
            </button>
          </div>
        </div>
      </div>
    </>,
    document.body
  );
}
