export default function DrawerDropDown({ title, isOpen, setIsOpen, menus }) {
  return (
    <div className="border-b-2 border-gray-200">
      <div
        className="flex justify-between items-center cursor-pointer py-3 px-3"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{title}</span>
        <span>{isOpen ? "▲" : "▼"}</span>
      </div>
      {isOpen && (
        <div className="flex flex-col mx-6 my-2 space-y-4">
          {menus.map((e, idx) => {
            return <span>{e}</span>;
          })}
        </div>
      )}
    </div>
  );
}
