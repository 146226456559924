import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginSuccess } from "../hook/authReducer";
import apiClient from "../services/apiClitent";
import BottomNav from "./BottomNav";
import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";
import Header2 from "./Header2";
import UserBottomNav from "./UserBottomNav";
import ExpertBottomNav from "./ExpertBottomNav";

function Layout({ children, fullWidth }) {
  const [width, setWidth] = useState(window.innerWidth);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      try {
        const decodedUser = jwtDecode(token);

        dispatch(loginSuccess(decodedUser));

        apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      } catch (error) {
        console.error("Invalid token", error);
        localStorage.removeItem("accessToken");
      }
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="min-h-screen flex flex-col break-keep text-wrap">
      <Header />
      {width >= 768 && <Header2 />}
      <Content>{children}</Content>
      <Footer />
      {width < 640 &&
        (!auth.isAuthenticated ? (
          <BottomNav />
        ) : auth.user?.auth === "ROLE_MEMBER" ? (
          <UserBottomNav />
        ) : (
          <ExpertBottomNav />
        ))}
    </div>
  );
}

export default Layout;
