import naver from "../assets/images/login/naver-login.png";
import kakao from "../assets/images/login/kakao-login.png";

function BtnSnsLogin({ sns }) {
  return (
    <div className="flex flex-col items-center justify-center text-center">
      <img src={sns == "카카오" ? kakao : naver} className="h-8" />
      <p className="text-sm text-gray-500">{sns} 아이디</p>
    </div>
  );
}

export default BtnSnsLogin;
