const email = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
//영어+숫자+특수문자(_,-)+글자수(8글자 이상)
const password =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

const phone = /^[0-9]{11}$/;
const tel = /^[0-9]{9,11}$/;

const name = /^[가-힣]{2,}$/;

const regex = { email, password, tel, name };

export default regex;
